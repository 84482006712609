import React, { useEffect, useState, useRef } from "react";
import {
  FaPaperclip,
  FaPaperPlane,
  FaFileAlt,
  FaTimes,
  FaSpinner,
} from "react-icons/fa";
import "./Chat.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdOutlineFileDownload } from "react-icons/md";

const Chat = () => {
  const [userQuery, setUserQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState([
    {
      speaker: "bot",
      text: "Hello, I’m PMR Pal, an AI bot designed specifically to help the Loan Officers of Premier Mortgage Resources. My role is to assist you with creative and friendly assistance every step of the way, making your job easier and more efficient! I can answer on scenarios, marketing questions and more. Find an error? Email advertising@pmrloans.com.",
    },
  ]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  // Golbal State Variables
  const access_token = useSelector((state) => state.user.access_token);

  const fileInputRef = useRef(null);
  const chatContainerRef = useRef(null);

  const handleChange = (e) => {
    setUserQuery(e.target.value);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadedFile(file);
      setFileUploading(true);
      setTimeout(() => {
        setFileUploading(false);
      }, 2000);
    }
  };

  const removeFile = () => {
    setUploadedFile(null);
    setDownloadUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  // const sendQuery = async (e) => {
  //   e.preventDefault();
  //   setConversation((prevConversation) => [
  //     ...prevConversation,
  //     { speaker: "user", text: userQuery },
  //   ]);
  //   setUserQuery("");
  //   setLoading(true);

  //   if (uploadedFile) {
  //     const formData = new FormData();
  //     formData.append("query", userQuery);
  //     formData.append("file", uploadedFile);

  //     const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();

  //     try {
  //       let response = await fetch("http://127.0.0.1:5000/uploadfile", {
  //         method: "POST",
  //         body: formData,
  //       });

  //       if (response.ok) {
  //         const blob = await response.blob();
  //         const contentDisposition = response.headers.get(
  //           "content-disposition"
  //         );

  //         let filename = `downloaded_file.${fileExtension}`;

  //         if (contentDisposition) {
  //           const matches = /filename="(.+)"/.exec(contentDisposition);
  //           if (matches != null && matches[1]) {
  //             filename = matches[1];
  //           }
  //         }

  //         const url = window.URL.createObjectURL(blob);
  //         setDownloadUrl({ url, filename });
  //         setConversation((prevConversation) => [
  //           ...prevConversation,
  //           {
  //             speaker: "bot",
  //             text: "File processed! Click the link below to download:",
  //             link: url,
  //             linkText: filename,
  //           },
  //         ]);
  //       } else {
  //         console.log("Error: ", response.statusText);
  //         window.alert("Download Failed");
  //       }
  //     } catch (error) {
  //       console.error("Error: ", error);
  //       setConversation((prevConversation) => [
  //         ...prevConversation,
  //         {
  //           speaker: "bot",
  //           text: "Sorry, something went wrong. Please try again.",
  //         },
  //       ]);
  //     } finally {
  //       setLoading(false);
  //       setUploadedFile(null);
  //     }
  //   } else {
  //     try {
  //       const response = await fetch("http://127.0.0.1:5000/query", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           query: userQuery,
  //           properties: {
  //             adu: "",
  //             technology: "",
  //             hudReo: "",
  //             escrowHoldback: "",
  //             rentalIncome: "",
  //             onLeave: "",
  //           },
  //         }),
  //       });

  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }

  //       const content = await response.json();
  //       setConversation((prevConversation) => [
  //         ...prevConversation,
  //         { speaker: "bot", text: content.result },
  //       ]);
  //     } catch (error) {
  //       console.error("Error: ", error);
  //       setConversation((prevConversation) => [
  //         ...prevConversation,
  //         {
  //           speaker: "bot",
  //           text: "Sorry, something went wrong. Please try again.",
  //         },
  //       ]);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  const sendQuery = async (e) => {
    e.preventDefault();
    // setFileDownloaded(true);
    setConversation((prevConversation) => [
      ...prevConversation,
      { speaker: "user", text: userQuery },
    ]);
    setUserQuery("");
    setLoading(true);

    if (uploadedFile) {
      setConversation((prevConversation) => [
        ...prevConversation,
        { speaker: "user", text: uploadedFile.name },
      ]);
      // setUploadedFile(null);

      const formData = new FormData();
      formData.append("query", userQuery);
      formData.append("file", uploadedFile);

      const fileExtension = uploadedFile.name.split(".").pop().toLowerCase();

      try {
        let response = await fetch(
          "https://king-prawn-app-556zp.ondigitalocean.app/uploadfile",
          // "http://127.0.0.1:5000/uploadfile",
          {
            method: "POST",
            body: formData,
          }
        );

        try {
          const json = await response.json();
          console.log(json);
          if (json.content) {
            setConversation((prevConversation) => [
              ...prevConversation,
              { speaker: "bot", text: json.content },
            ]);
            return;
          }
        } catch (e) {
          response = await fetch(
            "https://king-prawn-app-556zp.ondigitalocean.app/uploadfile",
            // "http://127.0.0.1:5000/uploadfile",
            {
              method: "POST",
              body: formData,
            }
          );
        }

        if (response.ok) {
          const blob = await response.blob();
          const contentDisposition = response.headers.get(
            "content-disposition"
          );

          let filename = `downloaded_file.${fileExtension}`;

          // if (contentDisposition) {
          //   const matches = /filename="(.+)"/.exec(contentDisposition);
          //   if (matches != null && matches[1]) {
          //     filename = matches[1];
          //   }
          // }

          const url = window.URL.createObjectURL(blob);
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              speaker: "bot",
              text: "File processed! Click the link below to download:",
              link: url,
              linkText: filename,
            },
          ]);
          // setDownloadUrl({ url, filename });
          // setFileDownloaded(false);
        } else {
          console.log("Error: ", response.statusText);
          window.alert("Download Failed");
        }

        // setConversation((prevConversation) => [
        //   ...prevConversation,
        //   {
        //     speaker: "bot",
        //     text: response.content ? response.content : "File Manipulated!",
        //   },
        // ]);
      } catch (error) {
        console.error("This is the error : ", error);
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            speaker: "bot",
            text: "Sorry, something went wrong. Please try again.",
          },
        ]);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch(
          "https://king-prawn-app-556zp.ondigitalocean.app/query",
          {
            // const response = await fetch("http://127.0.0.1:5000/query", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            body: JSON.stringify({
              query: userQuery,
              properties: {
                adu: "",
                technology: "",
                hudReo: "",
                escrowHoldback: "",
                rentalIncome: "",
                onLeave: "",
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const content = await response.json();
        setConversation((prevConversation) => [
          ...prevConversation,
          { speaker: "bot", text: content.result },
        ]);
      } catch (error) {
        console.error("This is the error : ", error);
        setConversation((prevConversation) => [
          ...prevConversation,
          {
            speaker: "bot",
            text: "Sorry, something went wrong. Please try again.",
          },
        ]);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [conversation]);

  return (
    <div
      style={{ height: "calc(100vh - 95px)" }}
      className="bg-gray-100 mt-2 flex flex-col max-w-2xl mx-auto"
    >
      <div className="bg-black p-4 text-white flex rounded-t-lg items-center">
        <span className="ms-3">PMR PAL</span>
      </div>

      <div ref={chatContainerRef} className="flex-1 overflow-y-auto p-4">
        <div className="flex flex-col space-y-2">
          {conversation.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.speaker === "bot" ? "justify-start" : "justify-end"
              }`}
            >
              <div
                className={`${
                  message.speaker === "bot"
                    ? "bg-gray-300 rounded-lg"
                    : "bg-blue-200 rounded-lg"
                } text-black p-2 rounded-lg max-w-sm`}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {message.text}
                {message.link && (
                  <div>
                    <a
                      href={message.link}
                      download={message.linkText}
                      className="text-blue-500 underline"
                    >
                      {message.linkText}
                    </a>
                  </div>
                )}
              </div>
            </div>
          ))}
          {loading && (
            <div className="flex justify-start items-center">
              <div className="loading-dots animate-blink p-3 ms-1 pt-4 rounded-lg bg-gray-300">
                <span className="dot animate-dot-1"></span>
                <span className="dot animate-dot-2"></span>
                <span className="dot animate-dot-3"></span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="p-4 bg-white border rounded-b-lg">
        {uploadedFile && (
          <div className="flex items-center bg-gray-200 rounded px-4 py-2 mb-2 justify-between">
            <FaFileAlt className="mr-2" />
            <span className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
              {uploadedFile.name}
            </span>
            {fileUploading ? (
              <FaSpinner className="ml-2 animate-spin" />
            ) : (
              <FaTimes className="ml-2 cursor-pointer" onClick={removeFile} />
            )}
          </div>
        )}
        <div className="row">
          <div className="col-12 flex items-center">
            <label className="ml-2 cursor-pointer">
              <FaPaperclip size={20} />
              <input
                type="file"
                accept=".xlsx,.csv"
                onChange={handleFileUpload}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </label>
            <input
              type="text"
              name="query"
              value={userQuery}
              onKeyDown={(e) =>
                userQuery.trim() !== "" && e.key === "Enter"
                  ? sendQuery(e)
                  : null
              }
              onChange={handleChange}
              placeholder="Type your message..."
              className="flex-1 rounded-full px-4 py-2"
              style={{
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
            />
            <button
              onClick={userQuery.trim() !== "" ? sendQuery : null}
              className="bg-black text-white rounded-full p-2 ml-2 focus:outline-none"
            >
              <FaPaperPlane size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
